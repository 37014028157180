@use '@angular/material' as mat;
@import '@angular/material/theming';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core(); 
 

 $ikea-blue: (
  50: #e0ebf4,
  100: #b3cde3,
  200: #80acd1,
  300: #4d8abf,
  400: #2671b1,
  500: #0058a3,
  600: #00509b,
  700: #004791,
  800: #003d88,
  900: #002d77,
  A100: #a5bfff,
  A200: #729bff,
  A400: #3f76ff,
  A700: #2564ff,
  contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: $black-87-opacity,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);


$ikea-yellow: (
  50: #fffbe0,
  100: #fff4b3,
  200: #ffed80,
  300: #ffe64d,
  400: #ffe026,
  500: #ffdb00,
  600: #ffd700,
  700: #ffd200,
  800: #ffcd00,
  900: #ffc400,
  A100: #ffffff,
  A200: #fffcf2,
  A400: #ffeebf,
  A700: #ffe8a6,
  contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: $black-87-opacity,
        600: $black-87-opacity,
        700: $black-87-opacity,
        800: $black-87-opacity,
        900: $black-87-opacity,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: $black-87-opacity,
    )
);

// // Define your application's custom theme.
 $ikea-primary: mat.define-palette($ikea-blue);
 $ikea-accent:  mat.define-palette($ikea-yellow);
 $ikea-theme:   mat.define-light-theme($ikea-primary, $ikea-accent);


// // Include the default theme styles.
 @include mat.all-legacy-component-themes($ikea-theme);


// // Define an alternate dark theme.
// $dark-primary: mat-palette($mat-blue-grey);
// $dark-accent:  mat-palette($mat-amber, A200, A100, A400);
// $dark-warn:    mat-palette($mat-deep-orange);
// $dark-theme:   mat-dark-theme($dark-primary, $dark-accent, $dark-warn);

// // Include theme styles for Angular Material components.
// @include angular-material-theme($dark-theme);

// $theme: $candy-app-theme;

// $primary: map-get($theme, primary);
// $accent: map-get($theme, accent);
// $borders: mat-palette($mat-amber, A200, A100, A400);






html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* COMMON STYLE */

.example-sidenav-content {
    padding: 0 !important;    
  }
  
  .custom-toolbar .mat-elevation-z6:first-of-type,
  .custom-toolbar .mat-elevation-z6:last-of-type {
    display: none;
  }

  .custom-toolbar {
    position: sticky;
    top: 0;
    overflow: hidden;
    z-index: 1000;
  }
  
  .custom-toolbar a {
    color: #333;
  }
  
  .mat-card {
    border-radius: 0px;
  }
  
  .mat-drawer-content .mat-card:not(.mat-card-standalone) {
    box-shadow: none !important;
    padding: 16px 12px !important;
  }
  
  .example-button-row mat-icon {
    margin-right: 2px;
    font-size: 23px;
  }
  
  .custom-toolbar .mat-toolbar-row > span {
    padding-right: 10px;
  }
  
  
  @supports (-ms-ime-align:auto) {
    .mat-header-cell.mat-table-sticky {
        position: relative !important;
    }
    
    .mat-cell.mat-table-sticky {
        position: relative !important;
    }
  }
  
  .mat-cell {
    font-size: 12px;
    border-right: 1px solid #f9f9f9;
    //border-right: mat-color($borders);
    border-left: 1px solid #f9f9f9;
    //border-left: mat-color($borders);
    border-collapse: collapse;
    padding: 0px 8px !important;
  }
  
  .mat-header-cell {
    font-size: 12px;
    border-right: 1px solid #f9f9f9; 
    //border-right: mat-color($borders);
    border-left: 1px solid #f9f9f9;
    //border-left: mat-color($borders);
    border-collapse: collapse;
    padding: 4px 0px 4px 8px !important;
  }
  
  .mat-sort-header-arrow {
    margin-left:1px;
  }
  
  .mat-row {
    height: 40px !important; 
  }
  
  .mat-drawer-side {
     padding: 3px !important;
  }
  
  .mat-paginator-sticky {
    bottom: 0px;
    position: sticky;
    z-index: 10;   
    left: 0 !important;
  }
    

  /* scrollbar */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
    
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #bbbbc1;
    border-radius: 2px !important;
  }
    
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #aaaab1;
  }
  
  
  .example-container {
    width: 100%;
  }
    
  .grid-container {
    max-height: 600px;
    overflow-x: auto;
  }

  .full-width{
    width: 100%
} 

  /*
  .grid-container {
    max-height: 600px;
    overflow: auto;
  }
  
  .example-container {
    width: 100%;
    height: calc(100% - 74px) !important;
  }
  */

  .logged-in-user {
    font-size: 15px;
  }

  /*.logged-in-user {
    position: absolute;
    font-size: 14px;
    clear: both !important;
    right: 20px;
    margin-top: 20px !important;
    text-align: center;
    z-index: 0 !important;
}

.mat-menu-trigger.mat-icon-button.mat-button-base {
    z-index: 2;
}*/



.mat-row:hover .mat-cell {
  background-color: #f9f9fb;
  border-left-color: #f0f0f6;
  border-right-color: #f0f0f6;
}

.highlight .mat-cell {
  background-color: #ebebee !important;
  border-left-color: #e0e0eb !important;    
  border-right-color: #e0e0eb !important;
}

.lf-item--width {
  min-width: 550px !important;
}